import React from 'react';
import { useSpring, animated } from 'react-spring';
const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`




export default function Resume() {

  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));
  return (
    <React.Fragment>
      <section id="resume">
        {/* Education
      ----------------------------------------------- */}
        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                <h3>National Institute of Technology, Jamshedpur</h3>
                <p className="info">B.Tech in Computer Science <span>•</span><em>CGPA: 8.95/10</em> <span>•</span><em className="date">July 2015 - May 2019</em></p>
                <p>
                  {/* Every year has a story but these 4 years its more like a novel "COLLEGE LIFE", everyone with approx same script. You never know, The strangers you met 4 years before just by saying a simple word "hello", become best pals 4 year down the lane.
I still remember , the day on which my nickname was assigned and by time, I forgot my real name and sobriquets for all , who remembers friend's real name :p <br />Late night parties , one night stand with every subject from 1st sem, hangout with friends become integral part of my life. How I will compensate all these things in my next phase ?
    <br />I still don't have any answer.<br />
                  "It takes a minute to say hello and forever to say goodbye" - Unknown. */}
                  <i>Done my Major in field of Machine Learning making project on <b>Hybrid Movie Recommendation System</b> using Collaborative and Content-based Filtering techniques. </i>
                </p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>Chinmaya Vidyalaya, B.S. city, Jharkhand</h3>
                <p className="info">Senior Secondary <span>•</span><em>Percentage: 95.8%</em> <span>•</span><em className="date">July 2013 – May 2015</em></p>
                <p>
                  {/* It was kind of a sedentary lifestyle. Made Lot of commitment with me. I took one of my most important step towards my dream lifestyle in this phase. I studied for 3 to 4 hrs daily apart from coaching everyday. */}
                  <i>Studied Physics, Chemistry and Mathematics along with Computer Science as my Primary subjects.</i>
                </p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>Agrasen D.A.V. Public School, Ramgarh, Jharkhand</h3>
                <p className="info">Secondary <span>•</span><em>CGPA: 10/10</em> <span>•</span><em className="date">May 2013</em></p>
                <p>
                  {/* Most of this phase passes under the guidance of parents. */}
                </p>
              </div>
            </div> {/* item end */}
          </div> {/* main-col end */}
        </div> {/* End Education */}
        {/* Work
      ----------------------------------------------- */}
        <div className="row work">
          <div className="three columns header-col">
            <h1><span>Work</span></h1>
          </div>
          <div className="nine columns main-col">
          <div className="row item">
              <div className="twelve columns">
                <h3>Job Ingestion team, LinkedIn</h3>
                <p className="info">Software Engineer <span>•</span> <em className="date">Jan 2022 - Present</em></p>
                <p>
                My team is responsible for creating system/framework to bring jobs from anywhere on the internet on to LinkedIn. It is also our team duty to normalize that data as much as possible. Once that data is standardized to a certain quality threshold, it get published.
                </p>
              </div>
            </div> {/* item end */}
          <div className="row item">
              <div className="twelve columns">
                <h3>Portfolio Accounting & Warehouse Team, Arcesium</h3>
                <p className="info">Software Engineer <span>•</span> <em className="date">Feb 2021 - Nov 2021</em></p>
                <p>
                I was working on TDP project which deals with triggering and capturing incremental profit and loss based on any amendment in past accounting data which processes around 50000 changed attribute within 20
                minutes.
                </p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>App Automate Team, BrowserStack</h3>
                <p className="info">Software Engineer <span>•</span> <em className="date">May 2019 - Feb 2021</em></p>
                <p>
                  My team develop one of the core product of BrowserStack i.e. we provide testing infrastructure with real mobile devices. I contribute in developing new features, monitoring different errors and providing better user experience of our product.
                </p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3> Tata Steel(ITS), Jamshedpur</h3>
                <p className="info">Summer Intern <span>•</span> <em className="date">June 2018 – July 2018</em></p>
                <p>
                  My responsibility was to make web app for Packaging and Inspection attribute for order for ITS,Tata Steel.
                </p>
              </div>
            </div> {/* item end */}
          </div> {/* main-col end */}
        </div> {/* End Work */}
        {/* Skills
      ----------------------------------------------- */}
        <div className="row skills">
          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>
          <div className="nine columns main-col skill-grid">
            {/* <div className="row"> */}
              {/* <div className="two columns"> */}
                {<animated.div
                  className="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/html.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div> */}
              {/* <div className="one columns"></div> */}
              {/* <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/css.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div> */}
              {/* <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/c++.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div> */}
              {/* <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/c.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div> */}
              {/* <div className="one columns"></div> */}
            {/* </div> */}
            {/* 
                   new row 
            */}
            {/* <div className="row">
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/ruby.jpeg')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/php.jpeg')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/rubyonrails.jpeg')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/javascriptanother.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
            </div> */}
            {/* <div className="row">
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/nodejs.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/sinatra.jpeg')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/mysql.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/git.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
            </div> */}
            {/* <div className="row">
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/react.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/sklearn.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/java.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
              <div className="two columns"> */}
                {<animated.div
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ backgroundImage: "url('../images/skills/bootstrap.png')", transform: props.xys.interpolate(trans) }}
                />}
              {/* </div>
              <div className="one columns"></div>
            </div> */}
          </div>{/* main-col end */}
          
        </div> {/* End skills */}
      </section>
    </React.Fragment>
  );

}

            // <div> 
            //  <ul className="skills">     className="bars"
            //     <li><span className="bar-expand photoshop" /><em>Photoshop</em></li>
            //     <li><span className="bar-expand illustrator" /><em>Illustrator</em></li>
            //     <li><span className="bar-expand wordpress" /><em>Wordpress</em></li>
            //     <li><span className="bar-expand css" /><em>CSS</em></li>
            //     <li><span className="bar-expand html5" /><em>HTML5</em></li>
            //     <li><span className="bar-expand jquery" /><em>jQuery</em></li>
            //  </ul>
            // </div>