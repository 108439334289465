import React, { Component } from 'react';
export default class About extends Component {
  render() {
    return (
      <React.Fragment>
      <section id="about">
        <div className="row">
          <div className="four columns">
            <img className="profile-pic" src="images/rishav.jpg" alt="Rishav" />
          </div>
          <div className="eight columns main-col">
            <h2>About Me</h2>
            <p style = {{fontSize: '18px'}}>I am Ambitious, Hardworking, Passionate & Persistent Software Engineer graduated in Bachelor of Technology (Computer Science) from 
              <i style = {{color: 'white'}}> National Institute of Technology, Jamshedpur</i>  with <b style = {{color: 'white'}}>8.95 CGPA on scale of 10</b>. I am specialised in C, C++ and having good knowledge of Ruby and Node.
               I am presently working at LinkedIn as a role of Software Engineer in the job-ingestion team. 
               Prior to this, I have also worked with Arcesium and Browserstack India Pvt. Ltd as a Software Engineer.
            </p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>Rishav Kumar</span><br />
                  <span>Opposite Rishi Weigh Bridge<br />
                    Bharech Nagar, Ramgarh, Jharkhand-829117
                  </span><br />
                  <span>+917992279560</span><br />
                  <span>rishavkumar15021998@gmail.com</span>
                </p>
              </div>
              <div className="columns download">
                <p>
                  <a href="https://drive.google.com/file/d/18276x8Zz_qeljycZ6Wt2YqeS04JXuHKF/view?usp=sharing" className="button"><i className="fa fa-download" />Download Resume</a>
                </p>
              </div>
            </div> {/* end row */}
          </div> {/* end .main-col */}
        </div>
      </section>
      </React.Fragment>
    );
  }
}